<template>
  <div class="mx-auto h-full bg-white">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
await usePageConfig()
</script>

<style>
#__nuxt {
  height: 100%;
  background: #ffffff;
}
</style>
